@import '_utility';

.home--wrap {
    background: $blu;
    padding-top: 40px;
}

.top--wrap {
    margin-bottom: 40px;

    @include media('>=tablet') {
        background: linear-gradient(
                        to bottom,
                        transparent calc(62.5% - 4.5px),
                        #fff        calc(62.5% - 4.5px),
                        #fff        calc(62.5% - 2.5px),
                        transparent calc(62.5% - 2.5px),
                        transparent calc(62.5% + 2.5px),
                        #fff        calc(62.5% + 2.5px),
                        #fff        calc(62.5% + 4.5px),
                        transparent calc(62.5% + 4.5px),
                        transparent 100%
                    );
    }
}

.top--img {
    max-width: 500px;
    margin: 0 auto;

    img {
        width: 100%;
    }
}

.splash--wrap {
    margin-bottom: 80px;
}

.splash--pretitle {
    font-family: $montserrat;
    font-size: 16pt;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 4pt;

    @include media('>=desktop') {
        font-size: 20pt;
    }
}

.splash--title {
    font-family: $montserrat;
    font-size: 26pt;
    font-weight: 900;
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1;

    .mini {
        font-size: 20pt;
    }

    @include media('>=desktop') {
        font-size: 36pt;
    }

    @include media('>=desktophd') {
        font-size: 56pt;
    }
}

.splash--txt {
    font-size: 16pt;

    @include media('>=tablet') {
        font-size: 20pt;
    }
}

.fumetto {
    margin: 0 auto;
    width: 100px;

    svg {
        width: 100%;
        height: auto;
    }
}


.tab--nav {
    position: relative;
    display: flex;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 20px;
    justify-content: space-between;

    @include media('>1600px') {
        padding: 0;
    }
}

.tab--nav__item {
    width: calc(100% / 6 - 5px);
    padding: 10px 5px;
    text-align: center;
    position: relative;
    cursor: pointer;
    opacity: 0.35;
    overflow-x: hidden;
    transition: width 250ms ease;

    .disappear {
        display: none;
    }

    .appear {
        display: block;
    }

    .subtitle {
        letter-spacing: 0pt;
    }

    &.current {
        opacity: 1;
        width: calc(100% / 3 * 2 - 10px);

        .disappear {
            display: block;
        }

        .appear {
            display: none;
        }

        @include media('>=desktop') {
            .disappear {
                display: none;
            }

            .appear {
                display: none;
            }
        }
    }

    @include media('>=tablet') {
        padding: 10px;
        width: calc(100% / 6 - 10px);

        &.current {
            opacity: 1;
            width: calc(100% / 3 * 2 - 10px);
        }

        .subtitle {
            letter-spacing: 2pt;
        }
    }

    @include media('>=desktop') {
        transition: transform 250ms ease;
        padding: 10px 5px 20px 5px;
        transform: translateY(10px);

        width: calc(100% / 3 - 10px);

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 55px 55px 0;
            border-color: transparent $blu transparent transparent;
        }

        &.current {
            opacity: 1;
            width: calc(100% / 3 - 10px);
            transform: translateY(0);
        }

        &:hover {
            transform: translateY(0);
        }

        .disappear {
            display: none;
        }

        .appear {
            display: none;
        }
    }
}

.calendario img {
    width: 20px;
    height: auto;
}

.tab--content {
    position: relative;
    display: none;
}

.show-tab {
    display: block;
}

.tab--layout {
    padding: 60px 0 140px 0;
}


.step--wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 2px solid $blu;
    margin-bottom: 20px;

    @include media('>=tablet') {
        flex-wrap: nowrap;
        margin-bottom: 40px;
    }
}

.step--orario {
    position: relative;
    padding: 5px 10px;
    text-align: center;
    font-size: 14pt;
    font-weight: bold;
    font-family: $montserrat;
    width: 100%;
    max-width: 270px;

    @include media('>=tablet') {
        margin-right: 20px;
        letter-spacing: 1px;
        font-size: 20pt;
        // width: auto;
    }
}

.corner--celeste {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 0;
    border-color: transparent $celeste transparent transparent;
}

.corner--azzurro {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 0;
    border-color: transparent $azzurro transparent transparent;
}

.corner--violetto {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 0;
    border-color: transparent $violetto transparent transparent;
}

.step--descrizione {
    font-size: 20pt;
    font-weight: bold;
    width: 100%;

    @include media('>=tablet') {
        width: auto;
    }
}


.presentatore--wrap {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;

    @include media('>=tablet') {
        flex-wrap: nowrap;
    }
}

.presentatore--intro {
    font-size: 13pt;
    font-weight: bold;
    font-style: italic;
    margin-right: 10px;
    width: 100%;

    @include media('>=tablet') {
        width: 100px;
    }
}

.presentatore--content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    @include media('>=tablet') {
        width: calc(100% - 100px - 10px);
    }
}

.presentatore--nome {
    padding: 0 10px;
    font-family: $montserrat;
    font-weight: bold;
    font-size: 16pt;
    text-transform: uppercase;
    margin-right: 10px;
}

.presentatore--info {
    font-size: 13pt;
    font-style: italic;
    width: 100%;

    @include media('>=tablet') {
        width: auto;
    }
}


.relatore--wrap {
    display: flex;
    flex-wrap: wrap;
}

.giustifica {
    justify-content: space-between;
}

.relatore--row {
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    @include media('>=desktop') {
        flex-wrap: nowrap;
        margin-bottom: 40px;
    }

    &.row100 {
        width: 100%;
    }

    &.row50 {
        width: 100%;
        align-items: flex-start;

        @include media('>=tablet') {
            width: calc(50% - 10px);
        }
    }

    &.row33 {
        width: 100%;

        @include media('>=tablet') {
            width: calc(50% - 10px);
        }

        @include media('>=desktophd') {
            width: calc(100% / 3 - 10px);
        }
    }

    &.margine { margin-right: 10px; }
}

.relatore--item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;

    @include media('>=tablet') {
        flex-wrap: nowrap;
        margin-bottom: 0;
    }

    @include media('>=desktop') {
        width: calc(50% - 15px - 80px);
    }
}

.relatore--item__half {
    width: 100%;
}

.relatore--item + .relatore--item {
    margin-top: 20px;

    @include media('>=desktop') {
        margin-top: 0;
    }
}

.relatore--img {
    display: inline-block;
    margin: 0 0 10px 0;
    text-align: center;
    width: 100%;

    @include media('>=tablet') {
        margin: 0 20px 0 0;
        width: 150px;
    }

    img {
        width: 100%;
    }
}

.relatore--bio {
    text-align: center;
    width: 100%;

    @include media('>=tablet') {
        text-align: left;
        width: calc(100% - 150px - 20px);
    }
}

.relatore--nome {
    display: inline-block;
    font-family: $montserrat;
    font-weight: bold;
    font-size: 16pt;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.relatore--info {
    font-size: 13pt;
    font-style: italic;
    width: 100%;

    @include media('>=tablet') {
        width: auto;
    }
}

.relatore--barra {
    display: none;
    margin: 0 40px;
    width: 15px;
    height: 100%;

    @include media('>=desktop') {
        display: block;
    }

    &.blu {
        border-left: 2px solid $blu;
        border-right: 2px solid $blu;
    }

    &.bianco {
        border-left: 2px solid #fff;
        border-right: 2px solid #fff;
    }
}

.relatore--intervento {
    width: 100%;

    @include media('>=desktop') {
        width: calc(50% - 15px - 80px );
    }

    &.full {
        width: 100%;
    }
}

.relatore--titolo {
    font-size: 20pt;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1;

    @include media('>=desktop') {
        font-size: 32pt;
    }
}

.relatore--txt {
    font-size: 12pt;
}



.card--wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card--filler {
    height: 0;
    visibility: hidden;

    @include media('>=phone') {
        width: calc(100% / 2 - 10px);
    }

    @include media('>=tablet') {
        width: calc(100% / 3 - 15px);
    }

    @include media('>=desktop') {
        width: calc(100% / 4 - 20px);
    }

    @include media('>=desktophd') {
        width: calc(100% / 6 - 20px);
    }
}

.card--item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
    text-decoration: none;
    transition: transform 250ms ease;

    @include media('>=phone') {
        width: calc(100% / 2 - 10px);
    }

    @include media('>=tablet') {
        width: calc(100% / 3 - 15px);
    }

    @include media('>=desktop') {
        width: calc(100% / 4 - 20px);
    }

    @include media('>=desktophd') {
        width: calc(100% / 6 - 20px);
    }
}

a.card--item:hover {
    transform: translateY(-10px);
}

.card--img {
    width: 100%;
    position: relative;

    img {
        display: block;
        width: 100%;
        height: auto;
        border: 20px solid #fff;
        box-sizing: border-box;
    }

    // &:after {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     border: 20px solid #fff;
    //     box-sizing: border-box;
    // }
}

.card--nome {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: $montserrat;
    font-size: 12pt;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 5px 10px;
    height: auto;
    width: 100%;
    height: 60px;
}


.popup--item {
    position: relative;
    background: $celeste;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.popup--content {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
}

.popup--img {
    width: 100%;
    margin-bottom: 20px;

    img {
        display: block;
        width: 100%;
        border: 20px solid #fff;
    }

    @include media('>=desktop') {
        width: calc(100% / 3);
        margin-right: 40px;
        margin-bottom: 0;
    }
}

.popup--info {
    width: 100%;

    @include media('>=desktop') {
        width: calc(100% / 3 * 2 - 40px);
    }
}

.popup--nome {
    font-family: $montserrat;
    font-size: 18pt;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 5px 10px;
    line-height: 1;
    margin-bottom: 20px;
    display: inline-block;

    @include media('>=desktop') {
        font-size: 28pt;
    }
}

.popup--ruolo {
    font-size: 13pt;
    font-style: italic;
    margin-bottom: 20px;
}

.popup--bio {
    font-size: 12pt;
}

.popup--date {
    padding: 20px 40px;
}

.popup--date__item {
    display: flex;
    align-items: center;
}

.popup--date__item + .popup--date__item {
    margin-top: 10px;
}

.popup--date__icon {
    margin-right: 20px;
}

.popup--calendario {
    width: 30px;

    img {
        width: 100%;
    }
}

.popup--date__txt {
    font-size: 14pt;
    font-weight: bold;

    @include media('>=desktop') {
        font-size: 22pt;
    }
}
