@import '_include_media';

$gabbia: 1300px;
$gabbiaStretta: 960px;

//### COLORI ###
$blu: #232b5a;
$azzurro: #69c7e9;
$violetto: #8ea6d6;
$celeste: #9cd6e4;

//### FONT ###
$zillaslab: 'Zilla Slab', serif;
$montserrat: 'Montserrat', sans-serif;

@mixin gabbia {
    max-width: $gabbia;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @include media('>1300px') {
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin gabbiaStretta {
    max-width: $gabbiaStretta;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @include media('>960px') {
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin absolutecenter {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

@mixin antialias()
{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
